import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  max-width: 130em;
  width: 100%;
  margin: 0 auto;
`;

export default Container;
