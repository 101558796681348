import React, { useState } from 'react';
import { AiOutlineCheck, AiOutlineClose, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IoCloseCircleSharp } from 'react-icons/io5';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { setupAPIClient } from 'services/api';

interface PasswordChangeModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

const PasswordChangeModal: React.FC<PasswordChangeModalProps> = ({
    isOpen,
    onRequestClose,
  }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [password, setPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState(false);
    const [typeInputConfirmPassword, setTypeInputConfirmPassword] = useState<'password' | 'text'>('password');
    const [typeInputPassword, setTypeInputPassword] = useState<'password' | 'text'>('password');
    const [messageErrorPassword, setMessageErrorPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    
  
    const handleChangePassword = async () => {
      try {
        if(newPassword !== confirmNewPassword) {
          toast.error('As senhas não coincidem!');
          return;
        }

        const requestData = {
          old_password: oldPassword,
          new_password: newPassword,
        };

        const api = setupAPIClient(); 
    
        await api.patch('/users/password', requestData);
    
        toast.success('Senha alterada com sucesso!.', {
          onClose: () => {setOldPassword(''); setNewPassword(''); onRequestClose()},
        });

      } catch (error:any) {
        if(error.response?.data?.message === 'invalid old password') {
          toast.error('A senha antiga não corresponde á senha atual.')
        } else {
          toast.error('Erro ao alterar a senha, tente novamente!')
        }
        console.error('Erro ao alterar a senha:', error);
      }
    };

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Alterar Senha"
        style={{
            overlay: {
              width: '100%',
              zIndex: 999,
              margin: 'auto',
            },
            content: {
              maxWidth: '358px',
              maxHeight: '550px',
              height: '650px',
              width: '80%',
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '15px',
              margin: 'auto',
              padding: '20px',
            },
          }}
      >
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        <ModalWrapper>
            <h2>Alterar Senha</h2>

            <IoCloseCircleSharp
              onClick={onRequestClose}
              size={35}
              fill="#ccc"
              style={{ position: 'absolute', right: '20px', top: '20px' }}
              className="closeButton"
            />

        <Group error={passwordError} style={{ position: 'relative' }}>
          <input
            type={typeInputPassword}
            placeholder="Senha antiga"
            value={oldPassword}
            onChange={(e) => {
              const validatePassword = /(?=.*[}{%&$@ç!#*,.^?~=+\-/*\-+.|])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[0-9]).{8,}/gm;

              setOldPassword(e.target.value);
              if (e.target.value.length && !validatePassword.test(e.target.value))
                setMessageErrorPassword(
                  'A senha deve possuir pelo menos 8 digitos, letras maiúsculas, minúsculas e caracteres especiais.',
                );
              else setMessageErrorPassword('');
              if (e.target.value.length >=8) setPasswordError(false);
            }}
          />
          {typeInputPassword === 'text' ? (
            <AiOutlineEyeInvisible
              style={{ position: 'absolute', right: '15px', top: '10px', cursor: 'pointer' }}
              size={25}
              onClick={() => setTypeInputPassword('password')}
            />
          ) : (
            <AiOutlineEye
              size={25}
              style={{ position: 'absolute', right: '15px', top: '10px', cursor: 'pointer' }}
              onClick={() => setTypeInputPassword('text')}
            />
          )}{password.length > 0 && (
            <ContainerMessageErrorPassword>
              <div className="lineAlert">
                {password.length < 8 ? <AiOutlineClose size={14} fill="#e36387" /> : <AiOutlineCheck size={14} fill="#5cc9aa" />}
                <span className={password.length >= 8 ? 'sucess' : 'error'}>8 caracteres contendo</span>
              </div>
              <div className="lineAlert">
                {/(?=.*[A-Z])/.test(password) ? (
                  <AiOutlineCheck size={14} fill="#5cc9aa" />
                ) : (
                  <AiOutlineClose size={14} fill="#e36387" />
                )}
                <span className={/(?=.*[A-Z])/.test(password) ? 'sucess' : 'error'}>1 Caractere maiúsculo</span>
              </div>
              <div className="lineAlert">
                {/(?=.*[a-z])/.test(password) ? (
                  <AiOutlineCheck size={14} fill="#5cc9aa" />
                ) : (
                  <AiOutlineClose size={14} fill="#e36387" />
                )}
                <span className={/(?=.*[a-z])/.test(password) ? 'sucess' : 'error'}>1 Caractere minúsculo</span>
              </div>
              <div className="lineAlert">
                {/(?=.*\d)/.test(password) ? <AiOutlineCheck size={14} fill="#5cc9aa" /> : <AiOutlineClose size={14} fill="#e36387" />}
                <span className={/(?=.*\d)/.test(password) ? 'sucess' : 'error'}>1 Caractere numérico</span>
              </div>
              <div className="lineAlert">
                {/[^a-zA-Z 0-9]+/g.test(password) ? (
                  <AiOutlineCheck size={14} fill="#5cc9aa" />
                ) : (
                  <AiOutlineClose size={14} fill="#e36387" />
                )}
                <span className={/[^a-zA-Z 0-9]+/g.test(password) ? 'sucess' : 'error'}>
                  1 Caractere especial (são aceitos: ?!@#$%^)
                </span>
              </div>
            </ContainerMessageErrorPassword>
          )}
          </Group>

          <Group error={passwordError} style={{ position: 'relative' }}>
            <input
              type={typeInputConfirmPassword}
              placeholder="Nova senha"
              value={newPassword}
              onChange={(e: any) => {
                setNewPassword(e.target.value);
                setPassword(e.target.value); // Atualiza o estado 'password' com a nova senha
                if (!e.target.value.length) setErrorConfirmPassword('Preenchimento obrigatorio!');
                else setErrorConfirmPassword('');
              }}
            />

            {typeInputConfirmPassword === 'text' ? (
              <AiOutlineEyeInvisible
                style={{ position: 'absolute', right: '15px', top: '10px', cursor: 'pointer' }}
                size={25}
                onClick={() => setTypeInputConfirmPassword('password')}
              />
            ) : (
              <AiOutlineEye
                size={25}
                style={{ position: 'absolute', right: '15px', top: '10px', cursor: 'pointer' }}
                onClick={() => setTypeInputConfirmPassword('text')}
              />
            )}
            {errorConfirmPassword.length ? <AlertMessage>{errorConfirmPassword}</AlertMessage> : null}
          </Group>

          <Group error={passwordError} style={{ position: 'relative' }}>
            <input
                type={typeInputConfirmPassword}
                placeholder="Confirmar senha"
                value={confirmNewPassword}
                onChange={(e: any) => {
                  setConfirmNewPassword(e.target.value);
                  if (e.target.value !== newPassword) {
                    setErrorConfirmPassword('As senhas não coincidem!');
                  } else {
                    setErrorConfirmPassword('');
                  }
                }}
              />

            {typeInputConfirmPassword === 'text' ? (
              <AiOutlineEyeInvisible
                style={{ position: 'absolute', right: '15px', top: '10px', cursor: 'pointer' }}
                size={25}
                onClick={() => setTypeInputConfirmPassword('password')}
              />
            ) : (
              <AiOutlineEye
                size={25}
                style={{ position: 'absolute', right: '15px', top: '10px', cursor: 'pointer' }}
                onClick={() => setTypeInputConfirmPassword('text')}
              />
            )}
          </Group>
            
          <button onClick={handleChangePassword}>Alterar Senha</button>
        </ModalWrapper>
      </Modal>
    );
  };

const ModalWrapper = styled.div`
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column; 

  h2 {
    font-size: 2rem;
    color: #8B8EE8;
    margin-bottom: 1rem;
  }

  label {
    font-size: 15px;

    display: flex;
    flex-direction: column; 

    margin: 10px;
  }

  input {
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #8B8EE8;
  }

  button {
    margin-top: 20px;
    background: #8B8EE8;
    border: none;
    padding: 1rem;
    border-radius: 7px;
    color: #fff;
    font-size: 15px;

    :hover {
      opacity: 0.9;
    }
  }
 
`
interface IInputProps {
  error: boolean;
}


const Group = styled.div<IInputProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 1rem;

  input {
    max-width: 100%;
    font-size: 1.5rem;
    padding: 1rem;
    border: ${({ error }) => (error ? '2px solid #e36387' : '2px solid #8B8EE8')};
    border-radius: 10px;
  }

  input::placeholder {
    font-size: 15px;
    color: #222222;
    font-weight: 500;
  }

  input:hover {
    border: solid 2px #797bb0;
    color: #0076a0;
  }

  input:focus {
    font-size: 14px;
    border: solid 2px #8b8ee8;
    color: #222222;
  }

  input:focus,
  select:focus {
    box-shadow: 0.3px 0.3px 0.3px;
    outline: 0;
  }

  img {
    position: absolute;
    margin: 10px 20px;
  }

  a {
    font-size: 15px;
    color: #e36387;
    float: right;
    transition: all 0.5s;
  }

  a {
    margin: 2.4rem;
  }

  @media (max-width: 766px) {
    a {
      font-size: 13px;
    }
  }
`;

const ContainerMessageErrorPassword = styled.div`
  background: #f5f5f5;
  border-radius: 10px;
  border: solid 1px #5cc9aa;
  position: relative;
  z-index: 9999;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .lineAlert {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-size: 14px;
      color: #ddd;
      margin-left: 5px;
    }

    .error {
      color: #e36387;
    }

    .sucess {
      color: #5cc9aa;
    }
  }

  @media (max-width: 766px) {
    width: 100%;
  }
`;

const AlertMessage = styled.span`
  font-size: 12px;
  color: #e36387;
  max-width: 300px;
`
  
  export default PasswordChangeModal;
